/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import Slider from "react-slick"

import { BenefitCard } from "components"

const SETTINGS = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  focusOnSelect: true,
  swipe: false,
  responsive: [
    {
      breakpoint: 1180,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 940,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 860,
      settings: {
        slidesToShow: 2,
        swipe: true,
        focusOnSelect: false,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "60px",
        swipe: true,
        focusOnSelect: false,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "32px",
        swipe: true,
        focusOnSelect: false,
      },
    },
    {
      breakpoint: 340,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "16px",
        swipe: true,
        focusOnSelect: false,
      },
    },
  ],
}

const Benefits = ({ instance, items }) => {
  const theme = useTheme()
  const styles = css`
    width: 100vw;
    overflow-x: hidden;
    margin-top: ${theme.margin[200]};

    @media screen and (max-width: 1194px) {
      padding: 0 ${theme.padding[300]};
    }

    @media screen and (max-width: 540px) {
      padding: 0;
      margin-top: 0 !important;
      position: relative;
      top: -16px;
    }

    .slick-list {
      margin-left: calc((100vw - 1146px) / 2);
      overflow: visible;

      @media screen and (max-width: 1194px) {
        margin-left: 0;
        overflow: hidden;
      }

      .slick-current {
        .BenefitCard {
          background: white;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);

          .icon {
            background: ${theme.color.red};

            * {
              fill: white;
            }
          }
        }
      }
    }
  `
  return (
    <div css={styles}>
      <Slider ref={instance} {...SETTINGS}>
        {items.map((item, i) => (
          <BenefitCard key={`benefit-${i}`} {...item} />
        ))}
      </Slider>
    </div>
  )
}
export { Benefits }
