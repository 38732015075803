/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useRef } from "react"
import { useIntl } from "gatsby-plugin-intl"

import { CarouselHeader } from "components"
import { BenefitsCarousel } from "modules"

import { benefitsData } from "config/data"

const Benefits = () => {
  const styles = css`
    background: #fcfcff;
    height: 290px;

    @media screen and (max-width: 1038px) {
      height: 320px;
    }

    @media screen and (max-width: 910px) {
      height: 340px;
    }

    @media screen and (max-width: 860px) {
      height: 310px;
    }

    @media screen and (max-width: 640px) {
      height: 340px;
    }

    @media screen and (max-width: 540px) {
      height: auto;
    }
  `

  const intl = useIntl()
  let benefitRef = useRef(null)

  const handlePrev = () => {
    benefitRef.current.slickPrev()
  }
  const handleNext = () => {
    benefitRef.current.slickNext()
  }

  return (
    <div css={styles}>
      <CarouselHeader
        type="light"
        title={
          <h4>
            {intl.formatMessage({ id: "benefits.title" })}{" "}
            <u>
              <span>{intl.formatMessage({ id: "benefits.underline" })}</span>
            </u>
          </h4>
        }
        buttons={
          <div className="prevnext">
            <button onClick={handlePrev}>&larr;</button>
            <button onClick={handleNext}>&rarr;</button>
          </div>
        }
      >
        {intl.formatMessage({ id: "benefits.description" })}
      </CarouselHeader>
      <BenefitsCarousel instance={benefitRef} items={benefitsData} />
    </div>
  )
}
export { Benefits }
