/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useIntl } from "gatsby-plugin-intl"

import IconLook from "./icon-look.svg"
import Icon360 from "./icon-360.svg"
import IconChat from "./icon-chat.svg"

import { BookCard } from "./"
import { Fragment } from "react"
import { useModal } from "context"

const Card = ({ icon, title, children }) => {
  const theme = useTheme()
  const styles = css`
    background: rgba(233, 43, 87, 0.9);
    width: 25%;
    height: 285px;
    padding: ${theme.padding[200]};
    display: flex;
    align-items: flex-end;
    position: relative;
    transition: background 0.25s ease;

    @media screen and (max-width: 1200px) {
      width: 23%;
    }

    @media screen and (max-width: 960px) {
      width: 33.33%;
      height: 200px;
      padding: ${theme.padding[300]};
    }

    @media screen and (max-width: 640px) {
      width: 100%;
      border-left: none;
      height: auto;
      align-items: center;
      padding: ${theme.padding[200]} ${theme.padding[300]};

      &:not(:first-of-type) {
        border-top: 1px solid rgba(58, 57, 86, 0.2);
      }
    }

    &:not(:first-of-type) {
      border-left: 1px solid rgba(58, 57, 86, 0.2);
    }

    .icon {
      position: absolute;
      top: ${theme.padding[200]};
      left: ${theme.padding[200]};

      @media screen and (max-width: 960px) {
        top: ${theme.padding[300]};
        left: ${theme.padding[300]};
      }

      @media screen and (max-width: 640px) {
        position: relative;
        top: 0;
        left: 0;
        margin-right: ${theme.margin[300]};
      }
    }

    .title {
      font-weight: 600;
      font-family: "Product Sans", sans-serif;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: ${theme.margin[100]};

      @media screen and (max-width: 640px) {
        margin-bottom: 0;
      }

      .arr {
        font-size: 24px;
        transform: translateX(-8px);
        opacity: 0;
        transition: opacity 0.125s ease 0.125s, transform 0.125s ease 0.125s;

        @media screen and (max-width: 640px) {
          display: none;
        }
      }
    }

    p {
      font-size: 14px;
      margin-bottom: 0;
    }

    &:hover {
      background: ${theme.color.red};
      cursor: pointer;

      .arr {
        transform: translateX(0);
        opacity: 1;
      }
    }
  `
  const { setActive } = useModal().setState

  return (
    <div css={styles} onClick={() => setActive(true)}>
      <img className="icon" src={icon} alt="" />
      <div className="content">
        <div className="title">
          <span>{title}</span>
          <span className="arr">&rarr;</span>
        </div>
        <p>{children}</p>
      </div>
    </div>
  )
}

const HeroLinks = () => {
  const theme = useTheme()
  const styles = css`
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: calc((100vw - 1146px) / 2 + 1146px);

    @media screen and (max-width: 1240px) {
      width: 100vw;
    }

    @media screen and (max-width: 960px) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 640px) {
      position: relative;
      width: 100%;
      bottom: unset;

      .BookCard {
        display: none !important;
      }
    }
  `

  const quickLinkStyles = css`
    display: none;
    @media screen and (max-width: 640px) {
      background: white;
      color: ${theme.color.red};
      font-weight: 600;
      line-height: 1;
      position: relative;
      padding: ${theme.padding[200]};
      font-family: "Product Sans", sans-serif;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: background 0.3s ease-out, color 0.3s ease-out;

      + .Cards {
        overflow: hidden;
        height: auto;
        max-height: 0;
        transition: max-height 0.3s ease-out;
        position: absolute;
        z-index: 3;
        width: calc(100vw - ${theme.padding[300]} * 2);
        left: ${theme.padding[300]};
      }

      &:focus {
        background: ${theme.color.red};
        color: white;
        outline: none;

        + .Cards {
          max-height: 600px;
        }
      }
    }
  `

  const intl = useIntl()

  return (
    <Fragment>
      <div css={quickLinkStyles} tabIndex="-1" className="quick-links">
        {intl.formatMessage({ id: "hero.quickLinks" })}
        <div>&darr;</div>
      </div>
      <div className="Cards" css={styles}>
        <Card
          title={intl.formatMessage({ id: "hero.links.viewing.title" })}
          icon={IconLook}
        >
          {intl.formatMessage({ id: "hero.links.viewing.description" })}
        </Card>
        <Card
          title={intl.formatMessage({ id: "hero.links.virtual.title" })}
          icon={Icon360}
        >
          {intl.formatMessage({ id: "hero.links.virtual.description" })}
        </Card>
        <Card
          title={intl.formatMessage({ id: "hero.links.question.title" })}
          icon={IconChat}
        >
          {intl.formatMessage({ id: "hero.links.question.description" })}
        </Card>
        <BookCard />
      </div>
    </Fragment>
  )
}
export { HeroLinks }
