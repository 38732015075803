/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useIntl } from "gatsby-plugin-intl"
import ExploreIMG from "images/explore.jpg"

const Explore = () => {
  const theme = useTheme()
  const styles = css`
    padding: ${theme.padding[900]} 0 ${theme.padding[700]};
    position: relative;
    min-height: 560px;

    @media screen and (max-width: 880px) {
      min-height: 640px;
    }

    @media screen and (max-width: 780px) {
      min-height: unset;
    }

    @media screen and (max-width: 720px) {
      padding: ${theme.padding[500]} 0;
    }

    .l {
      width: 50vw;
      position: absolute;
      right: 50vw;
      padding-right: ${theme.padding[600]};
      padding-top: ${theme.padding[600]};
      max-width: 597px;

      @media screen and (max-width: 1248px) {
        padding-left: ${theme.padding[300]};
      }

      @media screen and (max-width: 780px) {
        width: 100%;
        right: 0;
        position: relative;
        max-width: 100%;
      }

      @media screen and (max-width: 720px) {
        padding-top: 0;
      }

      @media screen and (max-width: 380px) {
        padding: 0 ${theme.padding[200]};
      }

      h4 {
        margin-bottom: ${theme.margin[300]};

        u {
          text-decoration: none;
          position: relative;
          white-space: nowrap;

          span {
            z-index: 2;
            position: relative;
          }

          &::before {
            display: block;
            content: "";
            height: 6px;
            width: calc(100% + 8px);
            z-index: 1;
            position: absolute;
            bottom: 4px;
            left: -3px;
            background: ${theme.color.red};
          }
        }
      }

      p {
        color: ${theme.color.darkpurple};
        font-size: 16px;
        font-family: "Product Sans", sans-serif;
        line-height: 1.6;

        @media screen and (max-width: 640px) {
          font-size: 14px;
        }

        &.lead {
          font-size: 18px;
          margin-bottom: ${theme.margin[300]};

          @media screen and (max-width: 640px) {
            font-size: 16px;
            font-weight: 600;
          }

          @media screen and (max-width: 380px) {
            font-size: 14px;
          }
        }
      }

      a {
        margin-top: ${theme.margin[300]};
        display: inline-block;
        font-family: "Product Sans", sans-serif;
        font-weight: 600;
      }
    }

    .r {
      width: 50vw;
      position: absolute;
      left: 50vw;
      top: 68px;
      border-radius: 8px;
      overflow: hidden;
      height: 520px;
      max-width: 756px;
      z-index: 1;

      @media screen and (max-width: 1198px) {
        width: 55vw;
        left: 45vw;
      }

      @media screen and (max-width: 780px) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  `
  const intl = useIntl()
  return (
    <div id="explore" css={styles}>
      <div className="l">
        <h4>
          <u>
            <span>{intl.formatMessage({ id: "explore.underline" })}</span>
          </u>{" "}
          {intl.formatMessage({ id: "explore.title" })}
        </h4>
        <p className="lead">{intl.formatMessage({ id: "explore.lead" })}</p>
        <p>{intl.formatMessage({ id: "explore.para" })}</p>
      </div>
      <div className="r">
        <img loading="lazy" src={ExploreIMG} alt="" />
      </div>
    </div>
  )
}
export { Explore }
