/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useRef, useState } from "react"
import { RoomDetailFooter } from "components"
import Slider from "react-slick"
import { useEffect } from "react"

const RoomDetail = ({ data }) => {
  const theme = useTheme()
  const styles = css`
    height: 366px;
    background: #121212;
    position: relative;

    @media screen and (max-width: 960px) {
      height: 320px;
    }

    @media screen and (max-width: 820px) {
      height: 366px;
    }

    @media screen and (max-width: 660px) {
      height: 320px;
    }

    @media screen and (max-width: 480px) {
      height: 260px;
    }

    @media screen and (max-width: 400px) {
      height: 220px;
    }

    .Slide {
      height: 366px;
      width: 100%;

      @media screen and (max-width: 960px) {
        height: 320px;
      }

      @media screen and (max-width: 820px) {
        height: 366px;
      }

      @media screen and (max-width: 660px) {
        height: 320px;
      }

      @media screen and (max-width: 480px) {
        height: 260px;
      }

      @media screen and (max-width: 400px) {
        height: 220px;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }

    .gallery-controls {
      height: 44px;
      pointer-events: none;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 ${theme.padding[300]};
      z-index: 2;

      button {
        appearance: none;
        border: none;
        background: ${theme.color.darkpurple};
        color: white;
        font-family: "Product Sans", sans-serif;
        font-size: 32px;
        border-radius: 4px;
        outline: none;
        pointer-events: all;
        height: 44px;
        width: 44px;
        font: inherit;

        &:hover {
          background: ${theme.color.red};
        }
      }
    }
  `

  const SETTINGS = {
    arrows: false,
    dots: false,
    initialSlide: 1,
    beforeChange: (current, next) => handleIsLayout(next),
  }

  const detailRef = useRef(null)
  const [isLayout, setIsLayout] = useState(false)

  useEffect(() => {
    if (detailRef.current) {
      detailRef.current.slickGoTo(1, true)
    }
  }, [data])

  const handlePrev = () => {
    detailRef.current.slickPrev()
  }

  const handleNext = () => {
    detailRef.current.slickNext()
  }

  const handleSelectLayout = () => {
    detailRef.current.slickGoTo(0)
  }

  const handleIsLayout = next => {
    if (next === 0) {
      if (!isLayout) {
        setIsLayout(true)
      }
    } else {
      setIsLayout(false)
    }
  }

  return (
    <div css={styles}>
      <Slider ref={detailRef} {...SETTINGS}>
        {data.images.map((image, i) => (
          <div className="Slide" key={image}>
            <img loading="lazy" key={image} src={image} alt="" />
          </div>
        ))}
      </Slider>
      <div className="gallery-controls">
        <button onClick={handlePrev}>&larr;</button>
        <button onClick={handleNext}>&rarr;</button>
      </div>
      <RoomDetailFooter
        isLayout={isLayout}
        onClick={handleSelectLayout}
        name={data.name}
        price={data.price}
      />
    </div>
  )
}
export { RoomDetail }
