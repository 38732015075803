/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import Slider from "react-slick"

import { galleryData } from "config/data"

const SETTINGS = {
  arrows: false,
  dots: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1198,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 860,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "60px",
        infinite: true,
      },
    },
  ],
}

const GalleryThumbs = ({ instance, beforeChange }) => {
  const theme = useTheme()
  const styles = css`
    height: 120px;
    border-radius: 4px;
    overflow: hidden;
    margin-top: ${theme.margin[200]};
    max-width: calc(756px + ${theme.margin[200]});
    margin-left: -${theme.margin[200]};
    z-index: 2;

    @media screen and (max-width: 720px) {
      display: none;
    }

    .Thumb {
      height: 120px;
      display: block;
      outline: none;
      padding-left: ${theme.padding[200]};

      img {
        height: 100%;
        width: 100%;
        display: block;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  `
  return (
    <div css={styles}>
      <Slider ref={instance} {...SETTINGS} beforeChange={beforeChange}>
        {galleryData.map((image, i) => (
          <div className="Thumb" key={`thumb-${i}`}>
            <img loading="lazy" src={image} alt="" />
          </div>
        ))}
      </Slider>
    </div>
  )
}
export { GalleryThumbs }
