/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { Wrapper } from "components"
import { useIntl } from "gatsby-plugin-intl"

import IconLook from "./icon-look.svg"
import Icon360 from "./icon-360.svg"
import IconChat from "./icon-chat.svg"

import { useModal } from "context"

const FootLink = ({ icon, title, children }) => {
  const theme = useTheme()
  const styles = css`
    background: rgba(233, 43, 87, 0.9);
    padding: ${theme.padding[200]};
    color: white;

    &:hover {
      background: ${theme.color.red};
      cursor: pointer;
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      font-family: "Product Sans", sans-serif;
      margin: ${theme.margin[200]} 0 ${theme.margin[100]};
    }

    p {
      font-size: 14px;
    }

    @media screen and (max-width: 640px) {
      display: flex;
      align-items: center;
      padding: ${theme.padding[200]} ${theme.padding[300]};

      @media screen and (max-width: 380px) {
        padding: 10px ${theme.padding[200]};
        border-radius: 4px;
      }

      img {
        margin-right: ${theme.padding[300]};

        @media screen and (max-width: 380px) {
          width: 24px;
          margin-right: ${theme.padding[200]};
        }
      }

      .title {
        margin-top: 0;
        margin-bottom: 4px;
        font-size: 14px;

        @media screen and (max-width: 380px) {
          margin-bottom: 0;
        }
      }

      p {
        font-size: 14px;
        margin-bottom: 0;

        @media screen and (max-width: 380px) {
          display: none;
        }
      }
    }
  `

  const { setActive } = useModal().setState

  return (
    <div css={styles} onClick={() => setActive(true)}>
      <img src={icon} alt="" />
      <div className="content">
        <div className="title">{title}</div>
        <p>{children}</p>
      </div>
    </div>
  )
}
const FooterLinks = () => {
  const theme = useTheme()
  const styles = css`
    background: ${theme.color.darkpurple};
    padding: 0 ${theme.padding[300]};
    width: 100vw;

    @media screen and (max-width: 640px) {
      padding: ${theme.padding[300]};
    }

    @media screen and (max-width: 380px) {
      padding: ${theme.padding[200]};
    }

    .W {
      margin: auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: ${theme.margin[200]};
      transform: translateY(-${theme.margin[400]});

      @media screen and (max-width: 940px) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: ${theme.margin[200]};
      }

      @media screen and (max-width: 640px) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 2px;
        transform: translateY(0);
      }

      @media screen and (max-width: 380px) {
        row-gap: 4px;
        // margin-bottom: ${theme.margin[100]};
      }
    }
  `
  const intl = useIntl()

  return (
    <div css={styles}>
      <Wrapper>
        <FootLink
          icon={IconLook}
          title={intl.formatMessage({ id: "hero.links.viewing.title" })}
        >
          {intl.formatMessage({ id: "hero.links.viewing.description" })}
        </FootLink>
        <FootLink
          icon={Icon360}
          title={intl.formatMessage({ id: "hero.links.virtual.title" })}
        >
          {intl.formatMessage({ id: "hero.links.virtual.description" })}
        </FootLink>
        <FootLink
          icon={IconChat}
          title={intl.formatMessage({ id: "hero.links.question.title" })}
        >
          {intl.formatMessage({ id: "hero.links.question.description" })}
        </FootLink>
        <FootLink
          icon={IconChat}
          title={intl.formatMessage({ id: "hero.links.book.title" })}
        >
          {intl.formatMessage({ id: "hero.links.book.description" })}
        </FootLink>
      </Wrapper>
    </div>
  )
}
export { FooterLinks }
