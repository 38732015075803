/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useRef, useState } from "react"
import { useIntl } from "gatsby-plugin-intl"

import { CarouselHeader, Map } from "components"
import { DistanceCarousel } from "modules"

import { distanceData } from "config/data"

const Distance = () => {
  const theme = useTheme()
  const styles = css`
    background: #f8f8ff;
    padding: ${theme.padding[600]} 0 0;
    width: 100vw;
    overflow: hidden;

    @media screen and (max-width: 880px) {
      padding: ${theme.padding[300]} 0 0;
    }

    @media screen and (max-width: 740px) {
      .prevnext {
        margin-left: auto !important;
      }
    }

    @media screen and (max-width: 380px) {
      padding: ${theme.padding[200]} 0 0;
    }

    .carousel {
      height: 200px;
      margin-top: ${theme.margin[300]};

      @media screen and (max-width: 380px) {
        margin-top: ${theme.margin[200]};
      }
    }

    .map {
      height: 420px;
      position: relative;
    }
  `

  const intl = useIntl()
  const distanceRef = useRef(null)
  const [index, setIndex] = useState(0)

  const handlePrev = () => {
    distanceRef.current.slickPrev()
  }

  const handleNext = () => {
    distanceRef.current.slickNext()
  }

  const handleBeforeChange = (current, next) => {
    setIndex(next)
  }

  return (
    <div css={styles}>
      <CarouselHeader
        type="light"
        title={<h4>{intl.formatMessage({ id: "distance.title" })}</h4>}
        buttons={
          <div className="prevnext">
            <button onClick={handlePrev}>&larr;</button>
            <button onClick={handleNext}>&rarr;</button>
          </div>
        }
      />
      <div className="carousel">
        <DistanceCarousel
          instance={distanceRef}
          items={distanceData.locations}
          beforeChange={handleBeforeChange}
        />
      </div>
      <div className="map">
        <Map index={index} />
      </div>
    </div>
  )
}
export { Distance }
