/** @jsx jsx */
import { jsx } from "@emotion/core"
import "slick-carousel/slick/slick.css"

import { Default as Layout } from "layouts"
import { SEO } from "components"
import { Modal } from "context"
import {
  Hero,
  Welcome,
  Benefits,
  Rooms,
  Gallery,
  Explore,
  Distance,
  FooterLinks,
} from "modules"

const IndexPage = () => {
  return (
    <Modal>
      <Layout>
        <SEO title="Home" />
        <Hero />
        <Welcome />
        <Benefits />
        <Rooms />
        <Gallery />
        <Explore />
        <Distance />
        <FooterLinks />
      </Layout>
    </Modal>
  )
}

export default IndexPage
