/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import Slider from "react-slick"
import { RoomCard } from "components"

const SETTINGS = {
  dots: false,
  arrows: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  focusOnSelect: true,
  swipe: false,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1194,
      settings: {
        slidesToShow: 4,
        infinite: true,
      },
    },
    {
      breakpoint: 860,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 0,
      },
    },
  ],
}

const Rooms = ({ instance, items, beforeChange }) => {
  const theme = useTheme()
  const styles = css`
    width: 100vw;
    overflow-x: hidden;
    margin: ${theme.margin[200]} 0 ${theme.margin[100]};

    @media screen and (max-width: 1194px) {
      padding: 0 ${theme.padding[300]};
    }

    .slick-list {
      margin-left: calc((100vw - 1146px) / 2);
      overflow: visible;

      @media screen and (max-width: 1194px) {
        margin-left: 0;
      }

      .slick-current {
        .RoomCard {
          .content {
            background: ${theme.color.red};
            color: white;

            .price {
              color: white;
            }

            .sold {
              background: white;
              color: ${theme.color.red};
            }

            &::before {
              background: ${theme.color.red};
              transform: translateY(0px) rotateZ(45deg);
            }
          }
        }
      }
    }
  `
  return (
    <div css={styles}>
      <Slider ref={instance} {...SETTINGS} beforeChange={beforeChange}>
        {items.map((item, i) => (
          <RoomCard key={`roomcard-${i}`} {...item} />
        ))}
      </Slider>
    </div>
  )
}
export { Rooms }
