/** @jsx jsx */
import { useState, useRef, useEffect } from "react"
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useIntl } from "gatsby-plugin-intl"

import {
  Wrapper,
  CarouselHeader,
  RoomDescription,
  TenantSidebar,
} from "components"
import { RoomsCarousel, RoomDetailCarousel } from "modules"

import { roomData } from "config/data"

const Rooms = () => {
  const theme = useTheme()
  const styles = css`
    background: ${theme.color.darkpurple};
    padding: ${theme.padding[600]} 0;
    height: auto;

    @media screen and (max-width: 460px) {
      padding-bottom: ${theme.padding[300]};
    }

    @media screen and (max-width: 380px) {
      padding: ${theme.padding[400]} 0;
    }

    .room {
      @media screen and (max-width: 1194px) {
        padding: 0 ${theme.padding[300]};
      }

      @media screen and (max-width: 380px) {
        padding: 0 ${theme.padding[200]};
      }

      .W {
        margin: 0 auto;
        display: flex;
        background: white;
        border-radius: 8px;
        overflow: hidden;
        z-index: 2;

        @media screen and (max-width: 820px) {
          display: block;
        }

        .l {
          width: 756px;

          @media screen and (max-width: 1194px) {
            width: 680px;
          }

          @media screen and (max-width: 1080px) {
            width: 580px;
          }

          @media screen and (max-width: 960px) {
            width: 480px;
          }

          @media screen and (max-width: 820px) {
            width: 100%;
          }
        }

        .r {
          flex: 1 0 auto;
          position: relative;

          &::before {
            opacity: 0.1;
            height: 100%;
            width: 100px;
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            display: block;
            background: linear-gradient(
              90deg,
              #89899d 0%,
              rgba(137, 137, 157, 0) 102.16%
            );
          }
        }
      }
    }
  `

  const intl = useIntl()
  let roomRef = useRef(null)
  const [index, setIndex] = useState(1)

  const handlePrev = () => {
    roomRef.current.slickPrev()
  }

  const handleNext = () => {
    roomRef.current.slickNext()
  }

  const handleBeforeChange = (current, next) => {
    setIndex(next)
  }

  useEffect(() => {
    setIndex(0)
  }, [])

  return (
    <div id="rooms" css={styles}>
      <CarouselHeader
        type="dark"
        title={
          <h4>
            {intl.formatMessage({ id: "rooms.header.title" })}{" "}
            <u>
              <span>
                {intl.formatMessage({ id: "rooms.header.underline" })}
              </span>
            </u>
          </h4>
        }
        buttons={
          <div className="prevnext">
            <button onClick={handlePrev}>&larr;</button>
            <button onClick={handleNext}>&rarr;</button>
          </div>
        }
      >
        {intl.formatMessage({ id: "rooms.header.description" })}
      </CarouselHeader>
      <RoomsCarousel
        instance={roomRef}
        items={roomData}
        beforeChange={handleBeforeChange}
      />
      <div className="room">
        <Wrapper>
          <div className="l">
            <RoomDetailCarousel data={roomData[index]} />
            <RoomDescription data={roomData[index]} />
          </div>
          <div className="r">
            <TenantSidebar index={index} data={roomData[index]} />
          </div>
        </Wrapper>
      </div>
    </div>
  )
}
export { Rooms }
