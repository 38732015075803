/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useModal } from "context"
import { useIntl } from "gatsby-plugin-intl"

const BookCard = () => {
  const theme = useTheme()
  const styles = css`
    background: ${theme.color.darkpurple};
    text-align: center;
    width: 25%;
    height: 275px;
    transform: translateY(32px);
    position: relative;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    @media screen and (max-width: 1200px) {
      width: 31%;
    }

    @media screen and (max-width: 960px) {
      width: 100%;
      transform: translateY(0);
      height: auto;
      padding: ${theme.padding[300]};

      @media screen and (max-width: 380px) {
        padding: ${theme.padding[200]};
      }

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        width: 100%;

        h4 {
          font-size: 22px;
        }

        .text {
          margin-right: ${theme.margin[300]};

          @media screen and (max-width: 380px) {
            margin-right: 0;
          }
        }

        @media screen and (max-width: 480px) {
          display: block;

          button {
            width: 100%;
            font-size: 14px;
          }
        }
      }
    }

    p {
      font-size: 14px;
      margin-bottom: ${theme.margin[300]};

      @media screen and (max-width: 960px) {
        margin: 0;
      }

      @media screen and (max-width: 480px) {
        margin-bottom: ${theme.margin[300]};
      }

      @media screen and (max-width: 380px) {
        margin-bottom: ${theme.margin[200]};
      }
    }

    button {
      background: ${theme.color.red};
      font: inherit;
      font-family: "Product Sans", sans-serif;
      appearance: none;
      border: 0;
      padding: ${theme.padding[200]} 0;
      width: 252px;
      text-align: center;
      color: white;
      font-size: 20px;
      font-weight: 600;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
      }
    }

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-top: 22px solid #2f2f46;
      border-left: 25px solid transparent;

      position: absolute;
      bottom: 0;
      left: -25px;

      @media screen and (max-width: 960px) {
        display: none;
      }
    }
  `
  const intl = useIntl()
  const { setActive } = useModal().setState

  return (
    <div className="BookCard" css={styles}>
      <div className="content">
        <div className="text">
          <h4>{intl.formatMessage({ id: "hero.links.book.title" })}</h4>
          <p>{intl.formatMessage({ id: "hero.links.book.description" })}</p>
        </div>
        <button onClick={() => setActive(true)}>
          {intl.formatMessage({ id: "hero.links.book.action" })}
        </button>
      </div>
    </div>
  )
}

export { BookCard }
