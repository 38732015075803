/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import Slider from "react-slick"

const Video = ({ instance, items }) => {
  const theme = useTheme()
  const styles = css`
    position: relative;
    width: 949px;
    height: 100%;
    margin: 0 auto;
    background: #eee;

    @media screen and (max-width: 1198px) {
      width: 100%;
    }

    .VideoItem {
      height: 430px;
      width: 100%;

      video {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      @media screen and (max-width: 720px) {
        height: 360px;
      }

      @media screen and (max-width: 640px) {
        height: 300px;
      }

      @media screen and (max-width: 560px) {
        height: 280px;
      }

      @media screen and (max-width: 500px) {
        height: 240px;
      }

      @media screen and (max-width: 440px) {
        height: 200px;
      }

      @media screen and (max-width: 360px) {
        height: 180px;
      }
    }

    .video-controls {
      position: absolute;
      pointer-events: none;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 80px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 1198px) {
        padding: 0 ${theme.padding[300]};
        height: 64px;
      }

      @media screen and (max-width: 640px) {
        height: 44px;
      }

      @media screen and (max-width: 380px) {
        padding: 0 ${theme.padding[200]};
      }

      @media screen and (max-width: 420px) {
        height: 32px;
      }

      button {
        pointer-events: all;
        height: 80px;
        width: 80px;
        appearance: none;
        border: none;
        background: ${theme.color.darkpurple};
        color: white;
        border-radius: 4px;
        font-size: 32px;
        font-family: "Product Sans", sans-serif;
        outline: none;
        transition: transform 0.125s ease;

        @media screen and (max-width: 1198px) {
          height: 64px;
          width: 64px;
          font-size: 28px;
        }

        @media screen and (max-width: 640px) {
          height: 44px;
          width: 44px;
          font-size: 24px;
        }

        @media screen and (max-width: 420px) {
          height: 32px;
          width: 32px;
          font-size: 18px;
        }

        &:first-of-type {
          transform: translateX(-40px);

          &:hover {
            transform: translateX(-40px) scale(1.1);
            background: ${theme.color.red};
          }

          @media screen and (max-width: 1198px) {
            transform: translateX(0);

            &:hover {
              transform: scale(1.1);
            }
          }
        }

        &:last-of-type {
          transform: translateX(40px);

          &:hover {
            transform: translateX(40px) scale(1.1);
            background: ${theme.color.red};
          }

          @media screen and (max-width: 1198px) {
            transform: translateX(0);

            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  `

  const SETTINGS = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipe: false,
    beforeChange: () => stopVideos(),
  }

  const stopVideos = () => {
    const videoList = document.querySelectorAll("video")
    videoList.forEach(vid => {
      vid.pause()
    })
  }

  const handlePrev = () => {
    instance.current.slickPrev()
  }

  const handleNext = () => {
    instance.current.slickNext()
  }

  return (
    <div css={styles}>
      <Slider ref={instance} {...SETTINGS}>
        {items &&
          items.map((item, i) => (
            <div key={`video-${i}`} className="VideoItem">
              <video controls poster={item.video.poster}>
                <source type="video/mp4" src={item.video.src} />
              </video>
            </div>
          ))}
      </Slider>
      <div className="video-controls">
        <button onClick={handlePrev}>&larr;</button>
        <button onClick={handleNext}>&rarr;</button>
      </div>
    </div>
  )
}
export { Video }
