/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import Slider from "react-slick"
import { DistanceCard } from "components"

const SETTINGS = {
  dots: false,
  arrows: false,
  infinite: false,
  swipe: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1198,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1090,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        swipe: true,
        focusOnSelect: false,
        centerPadding: "24px",
      },
    },
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        swipe: true,
        focusOnSelect: false,
        centerPadding: "16px",
      },
    },
  ],
}

const Distance = ({ instance, items, beforeChange }) => {
  const theme = useTheme()
  const styles = css`
    z-index: 2;
    position: relative;

    .slick-list {
      margin-left: calc((100vw - 1200px) / 2);
      overflow: visible;

      @media screen and (max-width: 1198px) {
        margin-left: 0;
        margin-left: ${theme.padding[300]};
        overflow: hidden;
      }

      @media screen and (max-width: 540px) {
        margin-left: 0;
      }
    }
  `
  return (
    <div css={styles}>
      <Slider ref={instance} {...SETTINGS} beforeChange={beforeChange}>
        {items.map((item, i) => (
          <DistanceCard key={`distance-${i}`} {...item} />
        ))}
      </Slider>
    </div>
  )
}
export { Distance }
