/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useRef, useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { useModal } from "context"

import { Wrapper, GallerySidebar } from "components"
import { GallerySingleCarousel, GalleryThumbsCarousel } from "modules"

import Icon360 from "./icon-360.svg"

const Gallery = () => {
  const theme = useTheme()
  const styles = css`
    background: ${theme.color.darkpurple};
    color: white;

    .head {
      padding: 0 ${theme.padding[300]};

      @media screen and (max-width: 380px) {
        padding: 0 ${theme.padding[200]};
      }

      .W {
        margin: 0 auto;
        text-align: center;
        max-width: 640px;
        margin-bottom: ${theme.margin[500]};

        @media screen and (max-width: 720px) {
          width: 100%;
          margin-bottom: ${theme.margin[200]};
        }

        u {
          text-decoration: none;
          position: relative;
          white-space: nowrap;

          span {
            z-index: 2;
            position: relative;
          }

          &::before {
            display: block;
            content: "";
            height: 6px;
            width: calc(100% + 8px);
            z-index: 1;
            position: absolute;
            bottom: 4px;
            left: -3px;
            background: ${theme.color.red};
          }
        }
      }
    }

    .body {
      position: relative;
      padding-top: ${theme.padding[400]};
      min-height: 400px;

      @media screen and (max-width: 1194px) {
        min-height: 340px;
      }

      @media screen and (max-width: 960px) {
        min-height: 300px;
      }

      @media screen and (max-width: 880px) {
        min-height: 320px;
      }

      @media screen and (max-width: 840px) {
        min-height: 300px;
      }

      @media screen and (max-width: 720px) {
        padding: ${theme.padding[200]} 0;
        min-height: auto;
      }

      .l {
        width: 56vw;
        border-radius: 8px;
        height: 530px;
        position: absolute;
        top: 0;
        right: 45vw;
        overflow: hidden;
        max-width: 750px;
        background: black;
        z-index: 1;

        .Slide {
          height: 530px;

          img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        @media screen and (max-width: 1194px) {
          width: 50vw;
          right: 50vw;
          height: 440px;

          .Slide {
            height: 440px;
          }
        }

        @media screen and (max-width: 960px) {
          height: 340px;

          .Slide {
            height: 340px;
          }
        }

        @media screen and (max-width: 840px) {
          width: 40vw;
          right: 60vw;
          height: 350px;

          .Slide {
            height: 350px;
          }
        }

        @media screen and (max-width: 720px) {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          height: 380px;
          overflow: visible;
          background: transparent;

          .Slide {
            height: 380px;
            padding: 0 ${theme.padding[100]};
            border-radius: 4px;
            overflow: hidden;
            z-index: 2;

            img {
              border-radius: 4px;
            }
          }
        }

        @media screen and (max-width: 640px) {
          height: 320px;

          .Slide {
            height: 320px;
          }
        }

        @media screen and (max-width: 520px) {
          height: 260px;

          .Slide {
            height: 260px;
          }
        }

        @media screen and (max-width: 420px) {
          height: 220px;

          .Slide {
            height: 220px;
          }
        }

        @media screen and (max-width: 360px) {
          height: 180px;

          .Slide {
            height: 180px;
          }
        }
      }

      .r {
        position: absolute;
        width: 50vw;
        right: 0;
        top: 0;
        overflow: hidden;
        z-index: 2;

        @media screen and (max-width: 1194px) {
          width: 55vw;
        }

        @media screen and (max-width: 840px) {
          width: 55vw;
        }

        @media screen and (max-width: 720px) {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          padding: ${theme.padding[100]} ${theme.padding[300]};
        }

        @media screen and (max-width: 380px) {
          padding: 0 ${theme.padding[200]};
          padding-bottom: ${theme.padding[300]};
        }
      }
    }
  `
  const { setActive } = useModal().setState
  const intl = useIntl()

  const thumbRef = useRef(null)
  const [index, setIndex] = useState(0)
  const [loaded, setLoaded] = useState(null)

  useEffect(() => {
    setLoaded(true)
  }, [])

  const handlePrev = () => {
    thumbRef.current.slickPrev()
  }

  const handleNext = () => {
    thumbRef.current.slickNext()
  }

  const handleBeforeChange = (current, next) => {
    setIndex(next)
  }

  return loaded ? (
    <div css={styles}>
      <div className="head">
        <Wrapper>
          <h4>
            {intl.formatMessage({ id: "gallery.title" })}{" "}
            <u>
              <span>{intl.formatMessage({ id: "gallery.underline" })}</span>
            </u>
          </h4>
          <p>{intl.formatMessage({ id: "gallery.description" })}</p>
        </Wrapper>
      </div>
      <div className="body">
        <div className="l">
          <GallerySingleCarousel index={index} />
        </div>
        <div className="r">
          <GallerySidebar>
            <button onClick={() => setActive(true)} className="CTA">
              <img src={Icon360} alt="" />
              {intl.formatMessage({ id: "gallery.bookVirtual" })}
            </button>
            <div className="nextprev">
              <button onClick={handlePrev}>&larr;</button>
              <button onClick={handleNext}>&rarr;</button>
            </div>
          </GallerySidebar>
          <GalleryThumbsCarousel
            beforeChange={handleBeforeChange}
            instance={thumbRef}
          />
        </div>
      </div>
      {/* <div className="body"><CarouselGallery /></div> */}
    </div>
  ) : (
    ""
  )
}
export { Gallery }
