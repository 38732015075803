/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useEffect, useRef } from "react"
import Slider from "react-slick"

import { galleryData } from "config/data"

const SETTINGS = {
  arrows: false,
  dots: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  swipe: false,
  responsive: [
    {
      breakpoint: 720,
      settings: {
        fade: false,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "60px",
        infinite: true,
        swipe: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        fade: false,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "24px",
        infinite: true,
        swipe: true,
      },
    },
    {
      breakpoint: 380,
      settings: {
        fade: false,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "16px",
        infinite: true,
        swipe: true,
      },
    },
  ],
}

const GallerySingle = ({ index }) => {
  const styles = css``
  const singleRef = useRef(null)

  useEffect(() => {
    singleRef.current.slickGoTo(index - 1)
  }, [index])

  return (
    <div css={styles}>
      <Slider ref={singleRef} {...SETTINGS}>
        {galleryData.map((image, i) => (
          <div className="Slide" key={`gallery-${i}`}>
            <img loading="lazy" src={image} alt="" />
          </div>
        ))}
      </Slider>
    </div>
  )
}
export { GallerySingle }
