/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { useRef } from "react"
import { useIntl } from "gatsby-plugin-intl"

import { Wrapper } from "components"
import { VideoCarousel } from "modules"
import { videoData } from "config/data"

const Welcome = () => {
  const theme = useTheme()
  const styles = css`
    padding: ${theme.padding[600]} ${theme.padding[300]};
    position: relative;
    height: auto;

    @media screen and (max-width: 540px) {
      padding: ${theme.padding[500]} ${theme.padding[300]};
    }

    @media screen and (max-width: 380px) {
      padding: ${theme.padding[400]} ${theme.padding[200]} 0;
    }

    &::before {
      display: block;
      content: "";
      background: linear-gradient(180deg, #24243f 0%, #fcfcff 100%);
      width: 100vw;
      height: 280px;
      pointer-events: none;
      position: absolute;
      top: 600px;
      left: 0;
      opacity: 0.05;

      @media screen and (max-width: 840px) {
        top: 520px;
        height: 350px;
      }

      @media screen and (max-width: 540px) {
        top: 360px;
        height: 280px;
      }

      @media screen and (max-width: 480px) {
        top: 320px;
      }

      @media screen and (max-width: 360px) {
        height: 320px;
        top: 260px;
      }
    }

    .W {
      margin: 0 auto;
      position: relative;

      h3 {
        max-width: 60%;
        font-size: 36px;
        line-height: 46px;
        color: ${theme.color.darkpurple};
        margin-bottom: ${theme.margin[600]};

        @media screen and (max-width: 1110px) {
          max-width: 70%;
        }

        @media screen and (max-width: 960px) {
          max-width: 100%;
        }

        @media screen and (max-width: 540px) {
          font-size: 24px;
          line-height: 1.2;
          margin-bottom: ${theme.margin[300]};
        }

        @media screen and (max-width: 380px) {
          margin-bottom: ${theme.padding[200]};
        }

        @media screen and (max-width: 360px) {
          font-size: 20px;
        }
      }

      u {
        text-decoration: none;
        position: relative;
        white-space: nowrap;

        span {
          z-index: 2;
          position: relative;
        }

        &::before {
          display: block;
          content: "";
          height: 8px;
          width: calc(100% + 16px);
          z-index: 1;
          position: absolute;
          bottom: 5px;
          left: -5px;
          background: ${theme.color.red};

          @media screen and (max-width: 540px) {
            height: 5px;
            bottom: 3px;
          }

          @media screen and (max-width: 360px) {
            height: 4px;
            bottom: 3px;
            left: -2px;
            width: calc(100% + 6px);
          }
        }
      }
    }
  `
  const intl = useIntl()
  let videoRef = useRef(null)

  return (
    <div id="welcome" css={styles}>
      <Wrapper>
        <h3>
          {intl.formatMessage({ id: "welcome.header" })}{" "}
          <u>
            <span>{intl.formatMessage({ id: "welcome.underline" })}</span>
          </u>
        </h3>
      </Wrapper>

      <VideoCarousel instance={videoRef} items={videoData} />
    </div>
  )
}
export { Welcome }
